import { useState } from 'react'
import Box from '@mui/material/Box';
import {Alert, AlertTitle} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

const DashboardAlert = () => {
  const [open, setOpen] = useState(true);
  return (
    <Box sx={{ width: '100%' }}>
      <Collapse in={open}>
        <Alert
          icon={false}
          severity="warning"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
        <AlertTitle>DATA UNDER SUBSTANTIAL UPDATE </AlertTitle>

        Due to the upcoming new season and transfer window for most of the covered leagues substantial change in players and clubs data are under implementation. 
        Meanwhile, some of data could be out of date, thus influencing the general accuracy of <strong>indicators.</strong>
        <p>We are working to ensure the following update timeline:  <br></br>
        <strong>JUL 2024: Season 2024-2025</strong> update (including relegations and promotions)  <br></br>
        <strong>SEP 2024</strong>: Players’ Transfers and indicators adjustment 
        </p>

        </Alert>
      </Collapse>
    </Box>
  )
}

export default DashboardAlert

